<template>
  <div>
    <v-card>
      <v-row
        class="mx-2 pt-2"
      >
        <v-col>
          <h3>Daftar Produk</h3>
        </v-col>
        <v-col
          cols="auto"
          class="ms-auto d-flex"
        >
          <input-icon-toggle
            v-model="state.productFilter.value.search"
            class="ms-2 me-2"
            :icon="icons.mdiMagnify"
            placeholder="Cari produk"
            @input="searchProduct()"
            @close="state.productFilter.value.search = ''; fetchProducts()"
          />
          <v-menu
            v-model="isArchiveOpen"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Produk Dihapus / Diarsipkan</span>
              </v-tooltip>
            </template>
            <ArchivedProduct
              :is-archive-open="isArchiveOpen"
              @update="fetchProducts()"
            />
          </v-menu>
          <v-icon
            size="26"
            @click="fetchProducts()"
          >
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        class="mx-2 mb-2"
      >
        <v-col
          cols="12"
          lg="auto"
          md="3"
        >
          <v-badge
            :value="recommendMode"
            bordered
            overlap
            class="w-100 cursor-pointer"
            @click.native="$refs.productRecommend.clear()"
          >
            <template
              #badge
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <v-btn
              outlined
              block
              large
              :loading="loadingRecommendation"
              :text="!recommendMode"
              :color="recommendMode ? 'primary' : null"
              @click.stop="$refs.productRecommend.show()"
            >
              Rekomendasi Produk
            </v-btn>
          </v-badge>
        </v-col>
        <!-- <v-col
          cols="12"
          lg="3"
          md="4"
        >
          <v-select
            placeholder="Sortir Berdasarkan"
            :items="[]"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
          />
        </v-col> -->
        <v-col
          v-if="!recommendMode"
          cols="12"
          lg="2"
          md="3"
        >
          <v-badge
            :value="state.productFilter.value.count > 0"
            bordered
            overlap
            class="w-100 cursor-pointer"
            @click.native="$refs.productFilter.clearFilter()"
          >
            <template
              #badge
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </template>
            <v-btn
              outlined
              block
              large
              :text="state.productFilter.value.count < 1"
              :color="state.productFilter.value.count > 0 ? 'primary' : null"
              @click.stop="$refs.productFilter.show()"
            >
              Filter {{ state.productFilter.value.count > 0 ? `(${state.productFilter.value.count})` : null }}
            </v-btn>
          </v-badge>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ms-0 ms-md-auto d-flex justify-space-between"
        >
          <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCardText }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Kartu</span>
            </v-tooltip>
          </v-btn-toggle>
          <v-tooltip
            v-if="$can('create', 'Product')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <split-button
                class="ms-auto ms-md-4"
                color="primary"
                @click="$refs.productForm.show()"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <template #list>
                  <v-list-item @click="$refs.importProduct.show()">
                    Import Data Produk
                  </v-list-item>
                </template>
              </split-button>
            </template>
            <span>Tambah Produk</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-card :loading="loading || loadingRecommendation">
        <v-tabs-items
          v-model="viewTab"
          touchless
        >
          <v-tab-item>
            <div class="d-flex justify-space-between grid-toolbar subtitle-1 rounded-t border-top">
              <div>
                <v-btn
                  tile
                  text
                  class="ps-6 px-3 font-weight-regular"
                  @click="handleToolbarClick('pdf')"
                >
                  <v-icon left>
                    {{ icons.mdiFilePdfBox }}
                  </v-icon>
                  Export PDF
                </v-btn>
                <v-btn
                  tile
                  text
                  class="px-3 font-weight-regular"
                  @click="handleToolbarClick('excel')"
                >
                  <v-icon left>
                    {{ icons.mdiFileExcelBox }}
                  </v-icon>
                  Export Excel
                </v-btn>
                <v-btn
                  tile
                  text
                  class="px-3 font-weight-regular"
                  @click="handleToolbarClick('edit')"
                >
                  <v-icon left>
                    {{ icons.mdiPencil }}
                  </v-icon>
                  Edit
                </v-btn>
                <v-btn
                  tile
                  text
                  class="px-3 font-weight-regular"
                  @click="handleToolbarClick('toggleGroup')"
                >
                  <v-icon left>
                    {{ icons.mdiSelectGroup }}
                  </v-icon>
                  Show Group
                </v-btn>
              </div>
              <div>
                <v-btn
                  ref="columnChooser"
                  tile
                  text
                  class="px-3 font-weight-regular"
                  @click="handleToolbarClick('columns', $refs.columnChooser.$el.getBoundingClientRect())"
                >
                  Columns
                  <v-icon right>
                    {{ icons.mdiChevronDown }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <ejs-grid
              id="productGrid"
              ref="productGrid"
              :data-source="productList"
              :allow-grouping="true"
              :allow-paging="true"
              :allow-sorting="true"
              :allow-resizing="true"
              :allow-reordering="true"
              :allow-pdf-export="true"
              :allow-excel-export="true"
              :show-column-chooser="true"
              :edit-settings="editSettings"
              :page-settings="pageSettings"
              :context-menu-open="handleContextMenuOpen"
              :context-menu-items="contextMenuItems"
              :group-settings="groupSettings"
              :action-begin="handleActionBegin"
              height="64vh"
            >
              <e-columns>
                <e-column
                  field="name"
                  header-text="Nama Produk"
                  :template="'nameTemplate'"
                >
                  <template #nameTemplate="{data}">
                    <div class="d-flex my-1 align-center">
                      <v-img
                        :src="'https://images.unsplash.com/photo-1588421357574-87938a86fa28?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'"
                        class="rounded mr-2 my-1 text--success"
                        height="60px"
                        width="60px"
                        max-height="60px"
                        max-width="60px"
                      />
                      <div>
                        <v-btn
                          class="font-medium px-0 d-block text-name"
                          color="primary"
                          text
                          tile
                          elevation="0"
                          @click="$router.push({ name: 'product-detail', params: { id: data.id } })"
                        >
                          {{ data.name }}
                        </v-btn>
                        <small
                          v-if="data.description"
                          style="white-space: normal;"
                        >{{ data.description }}</small>
                      </div>
                    </div>
                  </template>
                </e-column>
                <e-column
                  field="price"
                  header-text="Harga"
                  width="150"
                  :template="'priceTemplate'"
                >
                  <template #priceTemplate="{data}">
                    <span>{{ formatIDR(data.price) }}</span>
                  </template>
                </e-column>
                <e-column
                  field="type.0.name"
                  header-text="Tipe Produk"
                  width="150"
                  :template="'tagTemplate'"
                >
                  <template #tagTemplate="{data}">
                    <div>
                      <v-chip
                        v-for="tag in data.type"
                        :key="tag.id"
                        small
                        class="mr-2 my-1"
                        :color="tag.color"
                      >
                        {{ tag.name }}
                      </v-chip>
                    </div>
                  </template>
                </e-column>
                <e-column
                  field="stock"
                  header-text="Stok"
                  width="150"
                />
                <e-column
                  field="created_at"
                  header-text="Tanggal Dibuat"
                  width="150"
                  :template="'dateTemplate'"
                >
                  <template #dateTemplate="{data}">
                    <span>{{ dateFormat(data.created_at, 3) }}</span>
                  </template>
                </e-column>
                <e-column
                  field="updated_at"
                  header-text="Tanggal Diubah"
                  width="150"
                  :template="'dateTemplate'"
                >
                  <template #dateTemplate="{data}">
                    <span>{{ dateFormat(data.updated_at, 3) }}</span>
                  </template>
                </e-column>
              </e-columns>
            </ejs-grid>
            <v-menu
              v-model="menu"
              :position-x="menuX"
              :position-y="menuY"
              absolute
              offset-y
            >
              <v-list>
                <v-list-item
                  @click="$router.push({ name: 'product-detail', params: { id: selectedRow.id } })"
                >
                  Lihat Detail
                </v-list-item>
                <v-list-item
                  @click="$refs.productForm.update(selectedRow.id)"
                >
                  Ubah Data
                </v-list-item>
                <v-list-item
                  v-if="$can('del', 'Product')"
                  class="error--text"
                  @click="deleteProduct(selectedRow.id)"
                >
                  Hapus Produk
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab-item>
          <v-tab-item class="mx-4">
            <v-data-iterator
              :headers="headers"
              :items="productList"
              :items-per-page="20"
              item-key="id"
              show-select
              disable-sort
              :footer-props="{
                'items-per-page-options': [20, 40, 60],
                'items-per-page-text': 'Jumlah data',
              }"
              :server-items-length="productCount"
              mobile-breakpoint="0"
              :loading="loading"
              :options.sync="pagination"
            >
              <template v-slot:default="props">
                <v-row class="match-height">
                  <v-col
                    v-for="item in props.items.slice((pagination.page - 1) * pagination.itemsPerPage, (pagination.page - 1) * pagination.itemsPerPage + pagination.itemsPerPage)"
                    :key="item.name"
                    cols="12"
                    sm="6"
                    md="4"
                    xl="3"
                  >
                    <v-hover #default="{ hover }">
                      <v-card
                        outlined
                        class="d-flex flex-column flex-grow-1 pa-4"
                        @click="$router.push({ name: 'product-detail', params: { id: item.id } })"
                      >
                        <div class="d-flex mb-1 align-start">
                          <div>
                            <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ item.name }}</span>
                            <span class="text-caption font-medium">{{ item.description }}</span>
                            <div
                              v-if="item.type.length"
                              class="pt-3"
                            >
                              <v-chip
                                v-for="tag in item.type"
                                :key="tag.id"
                                small
                                class="mr-2 my-1"
                                :color="tag.color"
                              >
                                {{ tag.name }}
                              </v-chip>
                            </div>
                          </div>
                          <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-slide-x-reverse-transition mode="out-in">
                                <v-icon
                                  v-show="hover"
                                  size="20px"
                                  v-bind="attrs"
                                  class="ml-auto mt-2"
                                  v-on="on"
                                >
                                  {{ icons.mdiDotsVertical }}
                                </v-icon>
                              </v-slide-x-reverse-transition>
                            </template>
                            <v-list>
                              <router-link :to="{ name: 'product-detail', params: { id: item.id } }">
                                <v-list-item>Lihat Detail</v-list-item>
                              </router-link>
                              <v-list-item
                                v-if="$can('update', 'Product')"
                                @click="$refs.productForm.update(item.id)"
                              >
                                Ubah Data
                              </v-list-item>
                              <v-list-item
                                v-if="$can('del', 'Product')"
                                class="error--text"
                                @click="deleteProduct(item.id)"
                              >
                                Hapus Produk
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </template>
              <template
                #footer.page-text="page"
              >
                Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
              </template>
            </v-data-iterator>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>

    <product-form
      ref="productForm"
      @success="fetchProducts()"
    />

    <import-product
      ref="importProduct"
      @success="fetchProducts()"
    />

    <product-filter
      ref="productFilter"
      @close="fetchProducts()"
    />

    <product-recommend
      ref="productRecommend"
      :is-type="isType"
      @close="getRecommendation($event)"
      @clear="fetchProducts()"
      @update:isType="isType = $event"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { ref, onMounted } from '@vue/composition-api'
import {
  mdiPlus, mdiClose, mdiTable, mdiCardText, mdiDotsVertical,
  mdiMagnify, mdiRefresh, mdiArchive, mdiChevronDown,
  mdiSelectGroup, mdiFilePdfBox, mdiFileExcelBox, mdiPencil,
} from '@mdi/js'
import { useDebounceFn, useStorage } from '@vueuse/core'
import { createFieldMapper } from 'vuex-use-fields'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import { productRecommendations, products } from '@/graphql/queries'
import SplitButton from '@/components/inputs/SplitButton.vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import useProduct from './useProduct'
import ProductForm from './ProductForm.vue'
import errorHandling from '@/utils/errorHandling'
import ImportProduct from './ImportProduct.vue'
import ProductFilter from './ProductFilter.vue'
import router from '@/router'
import ArchivedProduct from '@/views/product/components/ArchivedProduct.vue'
import {
  ColumnChooser, ContextMenu, Edit, ExcelExport,
  Freeze, Group, LazyLoadGroup, Page, PdfExport,
  Reorder, Resize, Sort,
} from '@syncfusion/ej2-vue-grids'
import dateFormat from '@/utils/dateFormat'
import ProductRecommend from './ProductRecommend.vue'

const useFieldProduct = createFieldMapper({ getter: 'product/getField', setter: 'product/setField' })

export default {
  provide: {
    grid: [
      Page,
      Sort,
      Group,
      Freeze,
      Resize,
      Reorder,
      PdfExport,
      ExcelExport,
      Edit,
      ColumnChooser,
      LazyLoadGroup,
      ContextMenu,
    ],
  },
  components: {
    ProductForm,
    SplitButton,
    InputIconToggle,
    ImportProduct,
    ProductFilter,
    ArchivedProduct,
    ProductRecommend,
  },
  setup() {
    const isArchiveOpen = ref(false)
    const loading = ref(false)
    const filterCount = ref(0)
    const menu = ref(false)
    const menuX = ref(0)
    const menuY = ref(0)
    const selectedRow = ref(null)
    const isType = useStorage('is_type_matrix', false)
    const headers = ref([
      {
        text: 'Nama',
        value: 'name',
        width: '300px',
      },
      {
        text: 'Harga',
        value: 'price',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Stock',
        value: 'stock',
      },
      {
        text: 'Platform',
        value: 'platform',
      },
      {
        text: '',
        value: 'action',
      },
    ])
    const productGrid = ref()
    const productForm = ref()
    const productList = ref([])
    const productCount = ref(0)
    const viewTab = useStorage('viewTabProducts', 0)
    const { delProduct } = useProduct()
    const state = {
      ...useFieldProduct(['productFilter']),
    }
    const pagination = ref({})
    const productRecommend = ref()
    const recommendMode = ref(false)

    const fetchProducts = () => {
      const filter = state.productFilter.value
      loading.value = true
      recommendMode.value = false
      apolloClient.query({
        query: products,
        fetchPolicy: 'no-cache',
        variables: {
          search: filter.search ? filter.search : null,
          filter: {
            price: {
              max: filter.price.max ? +filter.price.max : null,
              min: filter.price.min ? +filter.price.min : null,
            },
            stock: {
              max: filter.stock.max ? +filter.stock.max : null,
              min: filter.stock.min ? +filter.stock.min : null,
            },
            type: filter.type.length ? filter.type : null,
          },
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        productList.value = result.data.products.products
        productCount.value = result.data.products.count
        loading.value = false
      }).catch(err => {
        loading.value = false
        router.push('/error')
        errorHandling(err, 'Daftar Produk')
      })
    }

    const deleteProduct = id => {
      delProduct(id).then(() => {
        fetchProducts()
      }).catch(err => {
        errorHandling(err, 'Hapus Produk')
      })
    }

    const searchProduct = useDebounceFn(() => {
      fetchProducts()
    }, 1000)

    onMounted(() => {
      fetchProducts()
    })

    const loadingRecommendation = ref(false)
    const getRecommendation = customerId => {
      loadingRecommendation.value = true
      recommendMode.value = true
      apolloClient.query({
        query: productRecommendations,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
          customer_id: customerId,
          is_type_matrix: isType.value,
        },
      }).then(result => {
        productList.value = result.data.productRecommendations.products
        productCount.value = result.data.productRecommendations.count
        if (result.data.productRecommendations.count === 0) {
          Vue.notify({
            title: 'Tidak ada rekomendasi produk',
            text: 'Belum cukup data untuk mendapatkan rekomendasi produk',
            type: 'warn',
          })
        }
        loadingRecommendation.value = false
      }).catch(err => {
        loadingRecommendation.value = false
        errorHandling(err)
      })
    }

    const formatIDR = number => new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(number)

    const pageSettings = ref(
      {
        pageSizes: [20, 40, 80],
        pageSize: 20,
        pageCount: 4,
        currentPage: 1,
      },
    )
    const editSettings = ref({
      allowEditing: true,
      allowDeleting: true,
      mode: 'Dialog',
    })
    const groupSettings = ref({
      enableLazyLoading: true,
      showDropArea: false,
    })
    const contextMenuItems = ref([
      'AutoFit', 'AutoFitAll',
      'PdfExport', 'ExcelExport',
      'SortAscending', 'SortDescending',
      'FirstPage', 'PrevPage', 'LastPage', 'NextPage',
    ])
    const handleToolbarClick = (action, event) => {
      if (action === 'pdf') {
        productGrid.value.pdfExport()
      } else if (action === 'excel') {
        productGrid.value.excelExport()
      } else if (action === 'edit') {
        productForm.value.update(productGrid.value.getSelectedRecords()[0].id)
      } else if (action === 'columns') {
        productGrid.value.openColumnChooser(event.right - productGrid.value.$el.getBoundingClientRect().x - 250, 0)
      } else if (action === 'toggleGroup') {
        productGrid.value.ej2Instances.groupModule.groupSettings.showDropArea = !productGrid.value.ej2Instances.groupModule.groupSettings.showDropArea
      }
    }

    const handleActionBegin = args => {
      /* eslint-disable no-param-reassign */
      switch (args.requestType) {
        case 'beginEdit':
          args.cancel = true
          productForm.value.update(args.rowData.id)
          break
        default:
          break
      }
      /* eslint-enable no-param-reassign */
    }

    const handleContextMenuOpen = args => {
      /* eslint-disable no-param-reassign */
      if (productGrid.value.getContentTable().contains(args.event.target)) {
        args.cancel = true
        menuX.value = args.left
        menuY.value = args.top - window.scrollY
        selectedRow.value = args.rowInfo.rowData
        menu.value = true
      }
      /* eslint-enable no-param-reassign */
    }

    const tagAccess = (field, data) => data[field].map(el => el.name).sort((a, b) => a.localeCompare(b)).join(', ')

    return {
      filterCount,
      loading,
      headers,
      viewTab,
      productList,
      productCount,
      fetchProducts,
      deleteProduct,
      state,
      searchProduct,
      formatIDR,
      productForm,
      productGrid,
      handleToolbarClick,
      pageSettings,
      editSettings,
      groupSettings,
      contextMenuItems,
      handleActionBegin,
      handleContextMenuOpen,
      menu,
      menuX,
      menuY,
      selectedRow,
      tagAccess,

      loadingRecommendation,
      productRecommend,
      recommendMode,
      getRecommendation,
      dateFormat,
      isType,

      pagination,
      isArchiveOpen,
      icons: {
        mdiPlus,
        mdiClose,
        mdiTable,
        mdiCardText,
        mdiDotsVertical,
        mdiMagnify,
        mdiRefresh,
        mdiArchive,
        mdiChevronDown,
        mdiSelectGroup,
        mdiFilePdfBox,
        mdiFileExcelBox,
        mdiPencil,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/syncfusion/material/individual-scss/grids/grid.scss';
@import '~@/styles/syncfusion-overrides.scss';
</style>

<style scoped>
.text-name.v-btn {
  height: auto !important;
}
.text-name.v-btn::before {
  background-color: transparent;
}
</style>
