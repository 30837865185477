<template>
  <v-dialog
    v-model="isOpen"
    max-width="600px"
  >
    <v-card
      class="pa-4"
    >
      <div class="d-flex justify-space-between mb-4">
        <span class="text-h6 font-medium primary--text">Rekomendasi Produk</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-row>
        <v-col
          cols="12"
        >
          <span class="text-caption mb-1 d-block">Pilih Pelanggan untuk Direkomendasikan</span>
          <customer-auto-suggest
            v-model="recommendCustomer"
            :label="null"
          />
        </v-col>
      </v-row>
      <div class="d-flex flex-column flex-md-row mt-5">
        <v-menu
          v-model="isPreferenceOpen"
          :close-on-content-click="false"
          offset-y
          nudge-bottom="8px"
          nudge-left="20px"
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator="menu">
            <v-tooltip bottom>
              <template v-slot:activator="tooltip">
                <v-icon
                  size="26"
                  class="my-auto"
                  v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                  v-on="{ ...menu.on, ...tooltip.on }"
                  @click.stop
                >
                  {{ icons.mdiCogOutline }}
                </v-icon>
              </template>
              <span>Pengaturan Rekomendasi</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>Pengaturan Rekomendasi</v-card-title>
            <v-card-subtitle>Rekomendasi berdasarkan:</v-card-subtitle>
            <div class="px-5">
              <v-radio-group
                v-model="isTypeMatrix"
                class="mt-0"
              >
                <v-radio
                  label="Produk (disarankan)"
                  :value="false"
                />
                <v-radio
                  label="Kategori"
                  :value="true"
                />
              </v-radio-group>
            </div>
          </v-card>
        </v-menu>
        <v-btn
          class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
          outlined
          @click="clear"
        >
          Reset
        </v-btn>
        <v-btn
          color="primary"
          @click="filter"
        >
          Rekomendasikan
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { mdiClose, mdiCogOutline } from '@mdi/js'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'

export default {
  components: {
    CustomerAutoSuggest,
  },
  props: {
    isType: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const recommendCustomer = ref(null)
    const isOpen = ref(false)
    const isPreferenceOpen = ref(false)

    // make computed getter and setter for isType props
    const isTypeMatrix = computed({
      get() {
        return props.isType
      },
      set(value) {
        emit('update:isType', value)
      },
    })

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    const clear = () => {
      recommendCustomer.value = null
      close()
      emit('clear')
    }

    const filter = () => {
      close()
      emit('close', recommendCustomer.value.id)
    }

    return {
      isOpen,
      show,
      close,
      clear,
      filter,
      recommendCustomer,
      isPreferenceOpen,
      isTypeMatrix,

      icons: {
        mdiClose,
        mdiCogOutline,
      },
    }
  },
}
</script>
