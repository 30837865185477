<template>
  <v-dialog
    v-model="isOpen"
    max-width="800px"
    @input="!$event ? $emit('close') : null"
  >
    <v-card
      class="pa-4"
    >
      <div class="d-flex justify-space-between mb-4">
        <span class="text-h6 font-medium primary--text">Filter Produk</span>
        <v-icon
          @click="close"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <span class="text-caption mb-1 d-block">Cari Produk</span>
          <v-text-field
            v-model="state.productFilter.value.search"
            placeholder="Cari nama atau SKU produk"
            outlined
            dense
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="text-caption mb-1 d-block">Harga Produk</span>
          <min-max-input
            v-model="state.productFilter.value.price"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="text-caption mb-1 d-block">Stok Produk</span>
          <min-max-input
            v-model="state.productFilter.value.stock"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="text-caption mb-1 d-block">Tipe Produk</span>
          <tag-product
            v-model="state.productFilter.value.type"
            :label="null"
            :read-only="true"
          />
        </v-col>
      </v-row>
      <div class="d-flex flex-column flex-md-row mt-4">
        <v-btn
          class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
          outlined
          @click="clearFilter()"
        >
          Reset Filter
        </v-btn>
        <v-btn
          color="primary"
          @click="filter"
        >
          Filter Produk
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { ref } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import MinMaxInput from '@/components/inputs/MinMaxInput.vue'
import TagProduct from '@/components/inputs/TagProduct.vue'

const useFieldProduct = createFieldMapper({ getter: 'product/getField', setter: 'product/setField' })

export default {
  components: {
    MinMaxInput,
    TagProduct,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const filterCount = ref(0)
    const state = {
      ...useFieldProduct(['productFilter']),
    }
    const countFilter = () => {
      const filter = state.productFilter.value
      const arrayKey = ['price', 'stock']
      for (const key in filter) {
        if (arrayKey.includes(key)) {
          // eslint-disable-next-line no-unused-expressions
          if (filter[key].max || filter[key].min) filterCount.value += 1
        }
        if (key === 'search' && filter[key] !== '') {
          filter[key].length ? filterCount.value += 1 : null
        }
        if (key === 'type' && filter[key].length) filterCount.value += 1
      }
    }
    const show = () => {
      isOpen.value = true
      filterCount.value = 0
    }

    const close = () => {
      isOpen.value = false
    }

    const filter = () => {
      countFilter()
      isOpen.value = false
      state.productFilter.value.count = filterCount.value
      emit('close')
    }

    const clearFilter = () => {
      state.productFilter.value.price = {
        min: null,
        max: null,
      }
      state.productFilter.value.stock = {
        min: null,
        max: null,
      }
      state.productFilter.value.type = []
      state.productFilter.value.search = ''
      state.productFilter.value.count = 0
      isOpen.value = false
      emit('close')
    }

    return {
      isOpen,
      state,
      show,
      close,
      clearFilter,
      filter,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style>

</style>
